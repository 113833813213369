define("discourse/plugins/tickets-filtering/discourse/initializers/filtering-by-assignees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'filtering-by-assignees',
    initialize() {
      //
    }
  };
});