define("discourse/plugins/tickets-filtering/discourse/connectors/bread-crumbs-right/by-assignees-filter", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse-common/lib/get-owner"], function (_exports, _component, _object, _service, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_ASSIGNEE_OPTION = {
    value: '*',
    name: 'all'
  };
  class ByAssigneesFilter extends _component.default {
    static shouldRender(args, helper) {
      const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
      return helper.siteSettings.filtering_by_assignees_enabled && helper.siteSettings.assign_enabled && router.currentRouteName !== "discovery.categories" && !args.editingCategory;
    }
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "taskActions", [_service.inject]))();
    #taskActions = (() => (dt7948.i(this, "taskActions"), void 0))();
    get assignees() {
      const initialAssignees = [DEFAULT_ASSIGNEE_OPTION];
      const assigneesSuggestions = this.taskActions.suggestions?.map(suggestion => ({
        value: suggestion.username,
        name: suggestion.username
      }));
      if (!assigneesSuggestions) {
        return initialAssignees;
      }
      return [...initialAssignees, ...assigneesSuggestions];
    }
    get assignee() {
      return DEFAULT_ASSIGNEE_OPTION.value;
    }
    changeAssignee(assignee) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("assigned", assignee);
      window.location.search = queryParams;
    }
    static #_4 = (() => dt7948.n(this.prototype, "changeAssignee", [_object.action]))();
  }
  _exports.default = ByAssigneesFilter;
});